<template>
  <div>
    <!-- BREADCRUMB -->
    <section
      class="py-9 bg-parallax"
      style="background-image: url(assets/img/background/background-img-1.jpg)"
    >
      <div class="container">
        <div class="breadcrumb-holder wow fadeInUp">
          <div>
            <h1 class="breadcrumb-title">FQ年齡測驗</h1>
            <p class="text-white font-size-16">
              大家聽說IQ、EQ，比較少聽過FQ(財商)，IQ高代表你很聰明，EQ高代表你情緒管理好，FQ則是代表
              你管理金錢的能力好。
              財商高也代表你的金融素養好，懂得金錢的價值，擁有正確花錢態度，更知
              道如何理財讓錢長大，現在就開始進行你的 FQ （財商 ) 小測驗～
            </p>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                FQ年齡測驗
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- content -->
    <section class="py-6 py-md-8">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="accordion" id="accordionOne">
              <div class="card">
                <div class="card-header bg-warning" id="headingOne">
                  <h5
                    class="icon-bg"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <span class="font-size-20"
                      >Q1. 你第一次拿到錢（錢幣或鈔票）是幾歲？</span
                    >
                  </h5>
                </div>

                <div
                  id="collapseOne"
                  class="collapse show shadow-sm rounded-sm"
                  aria-labelledby="headingOne"
                  data-parent="#accordionOne"
                >
                  <div class="card-body">
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu1"
                        id="qu1_a"
                        value="4"
                        v-model="score_1"
                      />
                      <label class="form-check-label" for="qu1_a">
                        A: 6歲之前
                      </label>
                    </div>
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu1"
                        id="qu1_b"
                        value="3"
                        v-model="score_1"
                      />
                      <label class="form-check-label" for="qu1_b">
                        B: 6-10歲
                      </label>
                    </div>
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu1"
                        id="qu1_c"
                        value="2"
                        v-model="score_1"
                      />
                      <label class="form-check-label" for="qu1_c">
                        C: 11-15歲
                      </label>
                    </div>
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu1"
                        id="qu1_d"
                        value="1"
                        v-model="score_1"
                      />
                      <label class="form-check-label" for="qu1_d">
                        D: 15歲以上
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header bg-success" id="headingTwo">
                  <h5
                    class="icon-bg collapsed"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <span class="font-size-20"
                      >Q2. 你聽過「記帳」嗎？是否有記帳的經驗？</span
                    >
                  </h5>
                </div>

                <div
                  id="collapseTwo"
                  class="collapse shadow-sm rounded-sm"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionOne"
                >
                  <div class="card-body">
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu2"
                        id="qu2_a"
                        value="4"
                        v-model="score_2"
                      />
                      <label class="form-check-label" for="qu2_a">
                        A: 有
                      </label>
                    </div>
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu2"
                        id="qu2_b"
                        value="0"
                        v-model="score_2"
                      />
                      <label class="form-check-label" for="qu2_b">
                        B: 沒有
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header bg-danger" id="headingThree">
                  <h5
                    class="icon-bg collapsed"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <span class="font-size-20"
                      >Q3. 父母有每月/每週固定給你零用錢嗎？</span
                    >
                  </h5>
                </div>

                <div
                  id="collapseThree"
                  class="collapse shadow-sm rounded-sm"
                  aria-labelledby="headingThree"
                  data-parent="#accordionOne"
                >
                  <div class="card-body">
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu3"
                        id="qu3_a"
                        value="4"
                        v-model="score_3"
                      />
                      <label class="form-check-label" for="qu3_a">
                        A: 有
                      </label>
                    </div>
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu3"
                        id="qu3_b"
                        value="0"
                        v-model="score_3"
                      />
                      <label class="form-check-label" for="qu3_b">
                        B: 沒有
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header bg-info" id="headingFour">
                  <h5
                    class="icon-bg collapsed"
                    data-toggle="collapse"
                    data-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    <span class="font-size-20"
                      >Q4.
                      如果爸媽有固定給零用錢，當你錢花光時，你會再跟父母要錢嗎？</span
                    >
                  </h5>
                </div>

                <div
                  id="collapseFour"
                  class="collapse shadow-sm rounded-sm"
                  aria-labelledby="headingFour"
                  data-parent="#accordionOne"
                >
                  <div class="card-body">
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu4"
                        id="qu4_a"
                        value="0"
                        v-model="score_4"
                      />
                      <label class="form-check-label" for="qu4_a">
                        A: 會，爸媽都會給
                      </label>
                    </div>
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu4"
                        id="qu4_b"
                        value="0"
                        v-model="score_4"
                      />
                      <label class="form-check-label" for="qu4_b">
                        B: 會，但是爸媽都不會再給錢
                      </label>
                    </div>
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu4"
                        id="qu4_c"
                        value="4"
                        v-model="score_4"
                      />
                      <label class="form-check-label" for="qu4_c">
                        C: 不會
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header bg-purple" id="headingFive">
                  <h5
                    class="icon-bg collapsed"
                    data-toggle="collapse"
                    data-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    <span class="font-size-20"
                      >Q5.
                      當逛街或去超商，看到很喜歡的東西，你會想全部買回家嗎？</span
                    >
                  </h5>
                </div>
                <div
                  id="collapseFive"
                  class="collapse shadow-sm rounded-sm"
                  aria-labelledby="headingFive"
                  data-parent="#accordionOne"
                >
                  <div class="card-body">
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu5"
                        id="qu5_a"
                        value="0"
                        v-model="score_5"
                      />
                      <label class="form-check-label" for="qu5_a">
                        A: 會
                      </label>
                    </div>
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu5"
                        id="qu5_b"
                        value="2"
                        v-model="score_5"
                      />
                      <label class="form-check-label" for="qu5_b">
                        B: 有時候會，當我很喜歡哪個東西時
                      </label>
                    </div>
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu5"
                        id="qu5_c"
                        value="4"
                        v-model="score_5"
                      />
                      <label class="form-check-label" for="qu5_c">
                        C: 不會
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header bg-pink" id="headingSix">
                  <h5
                    class="icon-bg collapsed"
                    data-toggle="collapse"
                    data-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    <span class="font-size-20"
                      >Q6. 你有儲蓄（存錢）的習慣嗎？</span
                    >
                  </h5>
                </div>
                <div
                  id="collapseSix"
                  class="collapse shadow-sm rounded-sm"
                  aria-labelledby="headingSix"
                  data-parent="#accordionOne"
                >
                  <div class="card-body">
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu6"
                        id="qu6_a"
                        value="4"
                        v-model="score_6"
                      />
                      <label class="form-check-label" for="qu6_a">
                        A: 有
                      </label>
                    </div>
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu6"
                        id="qu6_b"
                        value="0"
                        v-model="score_6"
                      />
                      <label class="form-check-label" for="qu6_b">
                        B: 沒有
                      </label>
                    </div>
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu6"
                        id="qu6_c"
                        value="3"
                        v-model="score_6"
                      />
                      <label class="form-check-label" for="qu6_c">
                        C: 沒有，但很想存錢可是沒有零用錢可存
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header bg-warning" id="headingSeven">
                  <h5
                    class="icon-bg"
                    data-toggle="collapse"
                    data-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    <span class="font-size-20"
                      >Q7. 請問你有自己專屬的銀行帳戶嗎？</span
                    >
                  </h5>
                </div>

                <div
                  id="collapseSeven"
                  class="collapse shadow-sm rounded-sm"
                  aria-labelledby="headingSeven"
                  data-parent="#accordionOne"
                >
                  <div class="card-body">
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu7"
                        id="qu7_a"
                        value="2"
                        v-model="score_7"
                      />
                      <label class="form-check-label" for="qu7_a">
                        A: 有，我不知道帳戶有多少錢，因為都是爸媽在管理
                      </label>
                    </div>
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu7"
                        id="qu7_b"
                        value="4"
                        v-model="score_7"
                      />
                      <label class="form-check-label" for="qu7_b">
                        B: 有，我知道帳戶有多少錢
                      </label>
                    </div>
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu7"
                        id="qu7_c"
                        value="0"
                        v-model="score_7"
                      />
                      <label class="form-check-label" for="qu7_c">
                        C: 沒有
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header bg-success" id="headingEight">
                  <h5
                    class="icon-bg collapsed"
                    data-toggle="collapse"
                    data-target="#collapseEight"
                    aria-expanded="false"
                    aria-controls="collapseEight"
                  >
                    <span class="font-size-20"
                      >Q8. 你有借錢給別人或是跟別人借錢的經驗嗎？</span
                    >
                  </h5>
                </div>

                <div
                  id="collapseEight"
                  class="collapse shadow-sm rounded-sm"
                  aria-labelledby="headingEight"
                  data-parent="#accordionOne"
                >
                  <div class="card-body">
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu8"
                        id="qu8_a"
                        value="4"
                        v-model="score_8"
                      />
                      <label class="form-check-label" for="qu8_a">
                        A: 幾乎沒有
                      </label>
                    </div>
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu8"
                        id="qu8_b"
                        value="3"
                        v-model="score_8"
                      />
                      <label class="form-check-label" for="qu8_b">
                        B: 有，跟我很熟的才借
                      </label>
                    </div>
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu8"
                        id="qu8_c"
                        value="2"
                        v-model="score_8"
                      />
                      <label class="form-check-label" for="qu8_c">
                        C: 有，反正都是朋友，通常我都會借
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header bg-danger" id="headingNine">
                  <h5
                    class="icon-bg collapsed"
                    data-toggle="collapse"
                    data-target="#collapseNine"
                    aria-expanded="false"
                    aria-controls="collapseNine"
                  >
                    <span class="font-size-20"
                      >Q9.
                      請問以下這幾個名詞：股票、ETF、基金、債券、定存、保險，你聽過幾個？</span
                    >
                  </h5>
                </div>

                <div
                  id="collapseNine"
                  class="collapse shadow-sm rounded-sm"
                  aria-labelledby="headingNine"
                  data-parent="#accordionOne"
                >
                  <div class="card-body">
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu9"
                        id="qu9_a"
                        value="4"
                        v-model="score_9"
                      />
                      <label class="form-check-label" for="qu9_a">
                        A: 6個都聽過
                      </label>
                    </div>
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu9"
                        id="qu9_b"
                        value="3"
                        v-model="score_9"
                      />
                      <label class="form-check-label" for="qu9_b">
                        B: 4-5個
                      </label>
                    </div>
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu9"
                        id="qu9_c"
                        value="2"
                        v-model="score_9"
                      />
                      <label class="form-check-label" for="qu9_c">
                        C: 2-3個
                      </label>
                    </div>
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu9"
                        id="qu9_d"
                        value="1"
                        v-model="score_9"
                      />
                      <label class="form-check-label" for="qu9_d">
                        D: 1個
                      </label>
                    </div>
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu9"
                        id="qu9_e"
                        value="0"
                        v-model="score_9"
                      />
                      <label class="form-check-label" for="qu9_e">
                        E: 通通沒聽過
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header bg-info" id="headingTen">
                  <h5
                    class="icon-bg collapsed"
                    data-toggle="collapse"
                    data-target="#collapseTen"
                    aria-expanded="false"
                    aria-controls="collapseTen"
                  >
                    <span class="font-size-20"
                      >Q10.
                      小宗定期定額買一檔共同基金，這檔基金每年賺6%（投資報酬6%），小宗想知道20年後他的基金會變成多少錢，請問他要用那一個表格來查？</span
                    >
                  </h5>
                </div>

                <div
                  id="collapseTen"
                  class="collapse shadow-sm rounded-sm"
                  aria-labelledby="headingTen"
                  data-parent="#accordionOne"
                >
                  <div class="card-body">
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu10"
                        id="qu10_a"
                        value="0"
                        v-model="score_10"
                      />
                      <label class="form-check-label" for="qu10_a">
                        A: 複利終值表
                      </label>
                    </div>
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu10"
                        id="qu10_b"
                        value="0"
                        v-model="score_10"
                      />
                      <label class="form-check-label" for="qu10_b">
                        B: 複利現值表
                      </label>
                    </div>
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu10"
                        id="qu10_c"
                        value="4"
                        v-model="score_10"
                      />
                      <label class="form-check-label" for="qu10_c">
                        C: 年金終值表
                      </label>
                    </div>
                    <div class="form-check mb-3 font-size-18">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="qu10"
                        id="qu10_d"
                        value="0"
                        v-model="score_10"
                      />
                      <label class="form-check-label" for="qu10_d">
                        D: 年金現值表
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="text-center">
                <button @click="calcScore" type="button" class="btn btn-danger text-uppercase">
                  送 出
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import tokenGenerator from '@/tokenGenerator.js'
export default {
  name: 'fqtest',
  data () {
    return {
      memberToken: '',
      memberId: '',
      scores: '',
      score_1: '',
      score_2: '',
      score_3: '',
      score_4: '',
      score_5: '',
      score_6: '',
      score_7: '',
      score_8: '',
      score_9: '',
      score_10: ''
    }
  },
  created () {
    const vm = this
    vm.getData()
  },
  mounted () {
    require('@/assets/js/base.js')
  },
  methods: {
    getData () {
      const vm = this

      vm.memberId = localStorage.getItem('memberId')
      vm.memberToken = localStorage.getItem('memberToken')
    },
    calcScore () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/firstTest`

      vm.scores = parseInt(vm.score_1) + parseInt(vm.score_2) + parseInt(vm.score_3) +
                  parseInt(vm.score_4) + parseInt(vm.score_5) + parseInt(vm.score_6) +
                  parseInt(vm.score_7) + parseInt(vm.score_8) + parseInt(vm.score_9) +
                  parseInt(vm.score_10)

      vm.axios.post(api, {
        token: tokenGenerator(),
        memberId: vm.memberId,
        score: vm.scores
      })
        .then((res) => {
          console.log(res)
          vm.$router.push('/member/myfqage')
        })
    }
  }
}
</script>
